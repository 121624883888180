<template>
    <div class="OffLineCurriculumDetail">
        <div class="left">
            <img :src="offLineCur.offline.cover" class="img" alt="" />
            <div class="detail">
                <div class="title">{{ offLineCur.offline.title }}</div>
                <div class="desc">{{ offLineCur.offline.desc }}</div>
                <div class="flex-alc center">
                    <div
                        class="submit mr-50"
                        :class="{
                            click: !offLineCur.register,
                            'back-gray': offLineCur.register,
                        }"
                        v-if="offLineCur.offline.need_signup"
                        @click="offLineCur.register ? '' : signUp()"
                    >
                        {{ offLineCur.register ? "已报名" : "报名" }}
                    </div>
                    <div
                        class="submit"
                        :class="{
                            click: offLineCur.status != 1,
                            'back-gray': offLineCur.status == 1,
                        }"
                        @click="
                            offLineCur.status == 1
                                ? ''
                                : (showofflineSubmit = true)
                        "
                    >
                        {{ offLineCur.status == 1 ? "已提交" : "提交完成" }}
                    </div>
                </div>
            </div>
        </div>
        <div class="right">
            <div class="teacher pb-19" v-show="recommendCur.length">
                <div class="text">课程推荐</div>
                <el-divider></el-divider>
                <div class="card">
                    <Curriculum
                        v-for="(item, index) in recommendCur"
                        :key="'cur' + index"
                        :curriculum="item"
                        class="mb-20"
                        :showBorder="true"
                        :border="6"
                        :isOff="1"
                        :imgSize="218"
                    ></Curriculum>
                </div>
            </div>
        </div>

        <el-dialog
            :visible.sync="showofflineSubmit"
            :show-close="true"
            class="offline-dialog"
            width="525px;"
        >
            <div class="title">提交完成</div>
            <el-divider></el-divider>
            <div class="pr-35 pl-35 wid_100">
                <el-input
                    type="textarea"
                    placeholder="说点什么吧"
                    v-model="textarea"
                    :rows="5"
                >
                </el-input>
            </div>
            <div class="upload">
                <div class="task-title">上传图片</div>
                <el-upload
                    :action="ossToken.host"
                    list-type="picture-card"
                    :on-success="uploadSuccess"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    :data="uploadData"
                    :before-upload="beforUpload"
                >
                    <i class="el-icon-plus"></i>
                </el-upload>
            </div>
            <div class="button click" @click="submit">确定</div>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible" :show-close="true">
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
    </div>
</template>

<script>
    import Curriculum from "@/components/Curriculum.vue";
    export default {
        name: "OffLineCurriculumDetail",
        components: {
            Curriculum,
        },
        data() {
            return {
                recommendCur: [], //推荐课程
                showofflineSubmit: false, //提交弹框

                file: [], //图片文件
                textarea: "", //文本
                fileName: "", //上传文件的名字
                dialogImageUrl: "", //大图Url
                dialogVisible: false, //大图弹框
                id: 0, //课程id
                offLineCur: { offline: {} }, //课程详情
            };
        },

        computed: {
            ossToken: function () {
                return this.$store.state.ossToken;
            },
            uploadData: function () {
                return {
                    key: this.ossToken.dir ? this.ossToken.dir + "/" : "",
                    policy: this.ossToken.policy,
                    OSSAccessKeyId: this.ossToken.accessid,
                    signature: this.ossToken.signature,
                };
            },
        },

        watch: {
            $route: function () {
                this.id = this.$route.query.id;
                this.$https
                    .get("/api/user/offline/list", {
                        limit: 4,
                    })
                    .then((res) => {
                        this.recommendCur = res.data.list;
                    });
                this.getDataDetail();
            },
        },

        async mounted() {
            this.id = this.$route.query.id;
            await this.$https
                .get("/api/user/offline/list", {
                    limit: 4,
                })
                .then((res) => {
                    this.recommendCur = res.data.list;
                });
            this.getDataDetail();
        },

        methods: {
            signUp() {
                this.$confirm("您确定要报名该课程吗？", "确认信息", {
                    distinguishCancelAndClose: true,
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                })
                    .then(() => {
                        this.$https
                            .post("/api/user/offline/register", { id: this.id })
                            .then((res) => {
                                if (res.errcode)
                                    return this.$message.error(res.msg);
                                this.offLineCur.register = true;
                                this.$message({
                                    message: "报名成功",
                                    type: "success",
                                });
                            });
                    })
                    .catch((action) => {
                        return false;
                    });
            },
            //上传文件前处理
            beforUpload(file) {
                this.uploadData.key =
                    (this.ossToken.dir ? this.ossToken.dir + "/" : "") + file.name;
                this.fileName = file.name;
            },
            //详情
            getDataDetail() {
                this.$https
                    .get("/api/user/offline/detail", { id: this.id })
                    .then((res) => {
                        if (res.errcode) return;
                        this.offLineCur = res.data;
                    });
            },
            //提交
            submit() {
                let list = [];
                this.file.forEach((i) => {
                    list.push(i.url);
                });
                let imageurl = list.join();
                this.$https
                    .post("/api/user/offline/submit", {
                        id: this.id,
                        text: this.textarea,
                        imageurl,
                    })
                    .then((res) => {
                        if (res.errcode) return this.$message.error(res.msg);
                        this.getDataDetail();
                        this.showofflineSubmit = false;
                    });
            },
            //查看大图
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            //上传成功
            uploadSuccess(e) {
                console.log(e);
                this.file.push({
                    url: `${this.ossToken.host}/${this.uploadData.key}`,
                    hash: this.fileName,
                });
            },
            //删除图片
            handleRemove(file, fileList) {
                let ruleForm = this.file;
                console.log(file);
                for (let i = 0; i < ruleForm.length; i++) {
                    if (!file.response) {
                        if (ruleForm[i].hash == file.name) {
                            this.file.splice(i, 1);
                            break;
                        }
                    } else {
                        if (ruleForm[i].hash == file.name) {
                            this.file.splice(i, 1);
                            break;
                        }
                    }
                }
            },
        },
    };
</script>

<style lang="less" scoped>
.OffLineCurriculumDetail {
    width: 1200px;
    margin: auto;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 100px;
    .left {
        width: 900px;
        .img {
            width: 100%;
            height: 568px;
            margin-bottom: 26px;
        }
        .detail {
            width: 100%;
            background: #ffffff;
            padding-bottom: 35px;
            .title {
                padding: 15px 30px;
                font-size: 18px;
                font-weight: 500;
                color: #000000;
            }
            .desc {
                font-size: 18px;
                font-weight: 500;
                color: #000000;
                padding: 18px 30px;
                margin-bottom: 160px;
            }
            .submit {
                width: 120px;
                height: 33px;
                background: linear-gradient(180deg, #ff3764, #ff2604);
                border-radius: 17px;
                font-size: 12px;
                font-weight: 400;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .back-gray {
                background: #aaa;
            }
        }
    }
    .right {
        .teacher {
            width: 100%;
            background: white;
            .card {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 19px;
            }
            .text {
                padding: 12px 17px 6px 17px;
                font-size: 18px;
                font-weight: 500;
                color: #000000;
            }
            .el-divider--horizontal {
                margin: auto;
                width: 260px;
            }
            .rankImg {
                width: 97px;
                height: 32px;
                margin-top: 14px;
            }
            .teacherInfo {
                display: flex;
                flex-direction: column;
                align-items: center;
                .head {
                    width: 110px;
                    height: 110px;
                    border-radius: 100%;
                    margin-bottom: 23px;
                }
                .fraction {
                    font-size: 14px;
                    color: #ff7500;
                    margin-right: 10px;
                }
                /deep/.el-rate {
                    height: auto;
                    .el-rate__icon {
                        margin-right: 0;
                        font-size: 22px;
                    }
                }
                .teacherName {
                    font-size: 20px;
                    font-weight: 500;
                    color: #000000;
                    margin-bottom: 29px;
                    margin-top: 15px;
                }
            }
            .number {
                font-size: 14px;
                font-weight: 500;
                color: #000000;
                padding: 0 17px;
            }
        }
    }
    .offline-dialog {
        /deep/.el-dialog {
            border-radius: 10px;
            .el-dialog__header {
                padding: 0;
            }
            .el-dialog__body {
                padding: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                .title {
                    font-size: 18px;
                    font-weight: 500;
                    color: #000000;
                    padding-top: 21px;
                    text-align: center;
                }
                .el-textarea__inner {
                    background: #f6f6f6;
                    border: 1px solid #d5d5d5;
                }
                .upload {
                    width: 100%;
                    padding: 0 35px;
                    .task-title {
                        font-size: 14px;
                        font-weight: 500;
                        color: #2d2d2d;
                        margin-bottom: 11px;
                        margin-top: 25px;
                    }
                    .el-upload--picture-card {
                        width: 102px;
                        height: 102px;
                        background: #f6f6f6;
                        border: 1px solid #d5d5d5;
                    }
                    .el-upload--picture-card i {
                        height: 102px;
                        width: 102px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .el-upload-list--picture-card .el-upload-list__item {
                        width: 102px;
                        height: 102px;
                    }
                }
                .button {
                    width: 161px;
                    height: 36px;
                    background: linear-gradient(180deg, #ff3764, #ff2604);
                    border-radius: 18px;
                    font-size: 16px;
                    font-weight: 500;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    margin-top: 48px;
                    margin-bottom: 50px;
                    justify-content: center;
                }
            }
        }
    }
}
</style>